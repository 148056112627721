<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <h1 class="systemTitle">Add Advertisement</h1>
          <v-form ref="investmentForm">
            <div class="imageBox">
              <v-label>Web Image</v-label>
              <img
                v-if="imageWebUrl"
                :src="imageWebUrl"
                alt=""
                @click="onImageWebPick"
              />

              <v-file-input
                class="mt-1"
                v-if="imageWebUrl == undefined"
                v-model="imageWeb"
                outlined
                prepend-inner-icon="mdi-camera"
                prepend-icon=""
                @change="onChangeImageWeb"
                placeholder="Web Banner Image"
              ></v-file-input>
            </div>
            <div class="imageBox">
              <v-label>App Image</v-label>
              <img
                v-if="imageAppUrl"
                :src="imageAppUrl"
                alt=""
                @click="onImageAppPick"
              />

              <v-file-input
                class="mt-1"
                v-if="imageAppUrl == undefined"
                v-model="imageApp"
                outlined
                prepend-inner-icon="mdi-camera"
                prepend-icon=""
                @change="onChangeImageApp"
                placeholder="App Banner Image"
              ></v-file-input>
            </div>
            <v-row>
              <v-col md="6">
                <v-label>Video Link</v-label>
                <v-text-field
                  v-model="videoLink"
                  outlined
                  placeholder="Video Link"
                  class="mt-1"
                ></v-text-field>
              </v-col>
              <v-col md="6">
                <v-label>Title</v-label>
                <v-text-field
                  v-model="title"
                  outlined
                  placeholder="Title"
                  class="mt-1"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-label>Description</v-label>
            <v-textarea
              v-model="description"
              outlined
              placeholder="Description"
              class="mt-1"
            ></v-textarea>

            <v-row>
              <v-col md="4">
                <v-label>Ads Type</v-label>
                <v-select
                  v-model="adsType"
                  :items="adsTypes"
                  outlined
                  placeholder="Ads Type"
                  item-text="name"
                  item-value="value"
                  class="mt-1"
                ></v-select>
              </v-col>
              <v-col md="4">
                <v-label>Ads For</v-label>
                <v-select
                  v-model="adsFor"
                  outlined
                  :items="adsFors"
                  placeholder="Ads For"
                  item-text="name"
                  item-value="value"
                  class="mt-1"
                ></v-select>
              </v-col>

              <v-col md="4">
                <v-label>Link To</v-label>
                <v-text-field
                  v-model="link"
                  outlined
                  placeholder="Title"
                  class="mt-1"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-btn @click="validate" v-if="!loading" large
              >Add Advertisement</v-btn
            >
            <v-btn v-if="loading" large loading></v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddBanner",
  data: () => ({
    myCroppa: {},
    postType: undefined,
    imageAppUrl: undefined,
    imageWebUrl: undefined,
    video: undefined,
    imageWeb: undefined,
    imageApp: undefined,
    loading: false,
    title: undefined,
    description: undefined,
    adsFor: undefined,
    adsType: undefined,
    link: undefined,
    videoLink: undefined,
    connectedTo: undefined,
    category: undefined,
    subCategory: undefined,
    videoUrl: undefined,
    subCategories: [],
    categories: [],
    adsTypes: [
      { name: "Internal", value: "internal" },
      { name: "External", value: "external" },
    ],
    adsFors: [
      { name: "Top", value: "top" },
      { name: "Middle", value: "middle" },
    ],
    postTypes: [
      { name: "Product", value: "product" },
      { name: "Category", value: "category" },
      { name: "SubCategory", value: "subCategory" },
      { name: "Blog", value: "blog" },
    ],
    connections: [],
  }),
  methods: {
    ...mapActions([
      "postAdvertisement",
      "fetchCategory",
      "getSingleAdvertisement",
      "getProductSelects",
      "getBlogSelects",
    ]),
    async validate() {
      this.loading = true;
      let formData = new FormData();
      if (this.title != undefined) {
        formData.append("title", this.title);
      }
      if (this.$route.params.id != undefined) {
        formData.append("_id",this.$route.params.id);
      }
      if (this.description != undefined) {
        formData.append("description", this.description);
      }
      if (this.imageWeb != undefined) {
        formData.append("imageWeb", this.imageWeb);
      }
      if (this.imageApp != undefined) {
        formData.append("imageApp", this.imageApp);
      }
      if (this.video != undefined) {
        formData.append("video", this.video);
      }
      if (this.videoLink != undefined) {
        formData.append("videoLink", this.videoLink);
      }
      if (this.adsType != undefined) {
        formData.append("adsType", this.adsType);
      }
      if (this.adsFor != undefined) {
        formData.append("adsFor", this.adsFor);
      }
      if (this.link != undefined) {
        formData.append("link", this.link);
      }

      if (this.postType != undefined) {
        formData.append("postType", this.postType);
      }
      await this.postAdvertisement(formData);
      if (this.allAdvertisement.status == 200 || this.allAdvertisement.status == 201) {
        this.$router.push("/business/banners");
      }
      this.loading = false;
    },

    onChangeImageWeb() {
      if (this.imageWeb != undefined) {
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          this.imageWebUrl = fileReader.result;
        });
        fileReader.readAsDataURL(this.imageWeb);
      } else {
        this.imageWebUrl = undefined;
      }
    },
    onChangeImageApp() {
      if (this.imageApp != undefined) {
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          this.imageAppUrl = fileReader.result;
        });
        fileReader.readAsDataURL(this.imageApp);
      } else {
        this.imageAppUrl = undefined;
      }
    },
    onImageWebPick() {
      this.imageWebUrl = undefined;
    },
    onImageAppPick() {
      this.imageAppUrl = undefined;
    },
  },
  computed: {
    ...mapGetters(["allAdvertisement"]),
  },
  async created(){
    if(this.$route.params.id != undefined){
      await this.getSingleAdvertisement(this.$route.params.id);
      this.title = this.allAdvertisement.results.title;
      this.description = this.allAdvertisement.results.description;
      this.videoUrl = this.allAdvertisement.results.video;
      this.imageAppUrl = this.allAdvertisement.results.imageApp;
      this.imageWebUrl = this.allAdvertisement.results.imageWeb;
      this.videoLink = this.allAdvertisement.results.videoLink;
      this.adsType = this.allAdvertisement.results.adsType;
      this.adsFor = this.allAdvertisement.results.adsFor;
      this.link = this.allAdvertisement.results.link;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.imageBox {
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
  .v-label{
    margin-bottom: 10px;
  }
  img {
    border: 1px solid #efefef;
    width: 200px;
    height: 200px;
    object-fit: contain;
    border-radius: 10px;
    margin-right: 10px;
  }
}
</style>